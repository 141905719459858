import React, { useEffect, useState } from 'react';
import { BASE_URL } from 'lib/api/client';
import { requestWrapper } from 'lib/api/auth';
import { Paper, Typography } from '@mui/material';
import TrendInsight from "./TrendInsight";
import VisitorEngagementTable from "./VisitorEngagement";
import { useTranslation } from 'react-i18next'

const SankeyDiagram = ({ documentId }) => {
  const [flowData, setFlowData] = useState([]);
  const { t } = useTranslation("global");


  useEffect(() => {
    const fetchData = async () => {
      if (!documentId) {
        console.error('Document ID is missing');
        return;
      }

      try {
        const response = await requestWrapper(`${BASE_URL}/api/v1/page_flows/flow_data?docuId=${documentId}`);
        if (response && Array.isArray(response["data"])) {
          setFlowData(response["data"]);
        } else {
          console.error('Unexpected response format', response);
        }
      } catch (error) {
        console.error('Error fetching flow data:', error);
      }
    };

    fetchData();
  }, [documentId]);

  useEffect(() => {
    if (flowData.length > 0) {
      const drawChart = () => {
        const data = new window.google.visualization.DataTable();
        data.addColumn('string', 'From');
        data.addColumn('string', 'To');
        data.addColumn('number', 'Weight');
        data.addRows(flowData);

        const options = {
          width: 900,
          height: 300,
        };

        const chart = new window.google.visualization.Sankey(document.getElementById('sankey_multiple'));
        chart.draw(data, options);
      };

      // Load the Google Charts library and set the callback
      window.google.charts.load('current', { packages: ['sankey'] });
      window.google.charts.setOnLoadCallback(drawChart);
    }
  }, [flowData]);

  return (
    <>
      <Paper style={{ margin: 20, maxWidth: '100%' }}>
        <Typography variant="h4" align="center" style={{ margin: 10, fontWeight: 'bold' }}>
          {t("mainSection.page-flow-title")}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {flowData.length > 0 ? (
            <div id="sankey_multiple" style={{ width: '900px', height: '300px' }}></div>
          ) : (
            <Typography variant="body1" align="center" style={{ margin: 10 }}>
              {t("mainSection.no-flow")}
            </Typography>
          )}
        </div>
      </Paper>
      {/* <Paper style={{ margin: 20, maxWidth: '100%' }}>
        {flowData.length > 0 ?  (
          <TrendInsight documentId={documentId} />
        ) : (
          <Typography variant="body1" align="center" style={{ margin: 10 }}>
            {t("mainSection.no-flow")}
          </Typography>
        )}
      </Paper> */}
      <Paper style={{ margin: 20, maxWidth: '100%' }}>
        <VisitorEngagementTable documentId={documentId} />
      </Paper>
    </>
  );
};

export default SankeyDiagram;
