import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  IconButton,
  Snackbar,
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material'; 
import axios from 'axios';

interface TranslationContext {
  industry: string;
  position: string;
  text: string;
}

const TranslationForm: React.FC = () => {
  const [context, setContext] = useState<TranslationContext>({
    industry: '',
    position: '',
    text: '',
  });

  const [translatedText, setTranslatedText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);


  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setContext((prevContext) => ({
      ...prevContext,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    setTranslatedText('');

    try {
      // Make a request to your AWS API Gateway
      const response = await axios.post('https://z7k0jjmtbg.execute-api.ap-northeast-1.amazonaws.com/beta/transales', {
        texts: context.text,
        industry_details: context.industry,
        position_details: context.position,
      });

      // Set the translated text in the state
      setTranslatedText(response.data.translated_text);
    } catch (err) {
      console.error('Error translating text:', err);
      setError('Failed to translate text. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    if (translatedText) {
      navigator.clipboard.writeText(translatedText).then(() => {
        // alert('Translated text copied to clipboard!');
        setSnackbarOpen(true);
      }).catch((err) => {
        console.error('Could not copy text: ', err);
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4, p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Sales Helper
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="業界"
          name="industry"
          fullWidth
          margin="normal"
          value={context.industry}
          onChange={handleInputChange}
          required
        />
        <TextField
          label="職種"
          name="position"
          fullWidth
          margin="normal"
          value={context.position}
          onChange={handleInputChange}
          required
        />
        <TextField
          label="内容"
          name="text"
          multiline
          rows={4}
          fullWidth
          margin="normal"
          value={context.text}
          onChange={handleInputChange}
          required
        />
        <Box sx={{ position: 'relative', mt: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            fullWidth
          >
            Translate
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </form>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      {translatedText && (
        <Paper elevation={3} sx={{ mt: 4, p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Translated Text
          </Typography>
          <Typography variant="body1">{translatedText}</Typography>
          <IconButton 
            onClick={handleCopy} 
            color="primary" 
            sx={{ mt: 2 }}
            aria-label="Copy translated text"
          >
            <ContentCopy />
          </IconButton>
        </Paper>
      )}

      <Snackbar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message="Translated text copied to clipboard!"
        autoHideDuration={3000} // Snackbar will auto-hide after 3 seconds
      />
    </Box>
  );
};

export default TranslationForm;
